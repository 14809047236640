/* eslint-disable react/no-unescaped-entities */
import React from 'react';
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

const mock = [
  
  {
    
    description:
      'English CCA',
    href : '/pick-and-speak-2024'
 
  },
  {
    
    description:
      'Tricolour Tribute',
    href : '/tricolour-tribute-2024'
 
  },
  {
    
    description:
      'Pick and Speak',
    href : '/pick-and-speak-class-6-8'
 
  },
  {
    
    description:
      'Avidreader 1 to 8',
    href : '/avidreader-1to8-2024'
 
  },
  {
    
    description:
      'English CCA 3 to 5',
    href : '/english-cca-3to5-2024'
 
  },
  {
    
    description:
      'Hindi CCA 1 And 2',
    href : '/hindi-cca-1-2-2024'
 
  },
  {
    
    description:
      'Kannada CCA 1 And 2',
    href : '/kannada-cca-1-2-2024'
 
  },
  {
    
    description:
      'Avidreader',
    href : '/avidreader-2024'
 
  },
  {
    
    description:
      'Kannada CCA 3 to 5',
    href : '/kannada-cca-3-5-2024'
 
  },
  {
    
    description:
      'Hindi CCA 3 to 5',
    href : '/hindi-cca-3-5-2024'
 
  },
  {
    
    description:
      'Avidreader 1 and 8',
    href : '/avidreader-1-8-2024'
 
  },
  {
  description:
  'Story Telling Grade 1 And 2',
href : '/story-telling-grade-1-2-2024'

},
];

const Sidebar2024 = () => {
  
  return (
    <Box component={Card} variant={'outlined'} padding={2}>
      <Typography
        variant="h6"
        data-aos={'fade-up'}
        sx={{
          fontWeight: 700,
          marginBottom: 2,
        }}
      >
        Literary Club 2024-25
      </Typography>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12}>
            <Box
              component={Card}
              width={1}
              height={1}
              boxShadow={0}
              borderRadius={0}
              display={'flex'}
              flexDirection={{ xs: 'column', md: 'row' }}
              sx={{ backgroundImage: 'none', bgcolor: 'transparent' }}
            >
              <Button href={item.href}>
                <Typography variant='h6'>
                  {item.description}
                  
                </Typography>
              </Button>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Sidebar2024;
